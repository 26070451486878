$bubble-size: calc(100vh / 6.3);

.bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 20px 30px rgb(0 0 0 / 20%), inset 0 10px 20px 0 white;
  position: absolute;
  height: $bubble-size;
  width: $bubble-size;

  &__center {
    top: 27vh;
  }

  &__top {
    top: calc(100vh / 4 - 15vh);
  }

  &__top-right {
    top: calc(100vh / 3 - 15vh);
    right: calc(50% - calc(100vh / 4.5));
  }

  &__bottom-right {
    top: calc(100vh / 2 - 15vh);
    right: calc(50% - calc(100vh / 4.5));
  }

  &__bottom {
    top: calc(100vh / 1.7 - 15vh);
  }

  &__bottom-left {
    top: calc(100vh / 2 - 15vh);
    left: calc(50% - calc(100vh / 4.5));
  }

  &__top-left {
    top: calc(100vh / 3 - 15vh);
    left: calc(50% - calc(100vh / 4.5));
  }
}


.bubble-dus {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  background-image: url('../assets/Light.webp');
  background-position: center;
  background-size: 200px;
  z-index: 0;
  height: $bubble-size;
  width: $bubble-size;

  &__center {
    top: 27vh;
  }

  &__top {
    top: calc(100vh / 4 - 15vh);
  }

  &__top-right {
    top: calc(100vh / 3 - 15vh);
    right: calc(50% - calc(100vh / 4.5));
  }

  &__bottom-right {
    top: calc(100vh / 2 - 15vh);
    right: calc(50% - calc(100vh / 4.5));
  }

  &__bottom {
    top: calc(100vh / 1.7 - 15vh);
  }

  &__bottom-left {
    top: calc(100vh / 2 - 15vh);
    left: calc(50% - calc(100vh / 4.5));
  }

  &__top-left {
    top: calc(100vh / 3 - 15vh);
    left: calc(50% - calc(100vh / 4.5));
  }
}