ionic-selectable-modal {
  .not-found {
    padding: 16px;
  }
}

ion-app,
.ion-page {
  $max-width: 768px;
  $bgOpacity: 0.25;
  align-items: center;

  ion-header,
  ion-footer {
    max-width: $max-width;
    margin: 0 auto;

    ion-toolbar {
      color: black;
    }

    .opacity-toolbar {
      --background: rgba(#E6E6E6, .62)
    }
  }

  .answer-modal>.ion-page {
    align-items: inherit;
  }

  ion-content {
    --background: transparent;

    &.max-width {
      max-width: $max-width;

      &::part(background) {
        background: white;
        opacity: $bgOpacity;
      }
    }
  }

  google-map {
    height: 1000%;
    width: 100%;

    .gmnoprint,
    .gm-style-cc {
      display: none;
    }
  }

  .map-container {
    height: 100% !important;
    width: 100% !important;
    position: static !important;
    cursor: pointer;
  }

  @media screen and (max-width:768px) {
    ion-content {
      &.max-width {
        &::part(background) {
          background: none;
          opacity: 1;
        }
      }
    }
  }
}

ion-select {
  --placeholder-color: black;
  --placeholder-opacity: 0.5;

  width: 100%;
  justify-content: space-between;

  &::part(text) {
    white-space: inherit;
  }
}

ion-modal.modal-default.show-modal~ion-modal.modal-default {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal {
  .ion-page {
    align-items: normal;
  }

  &.alert-modal {
    --width: 350px;
    --max-width: 768px;
    --height: auto;

    ion-header {
      &:after {
        height: 0;
      }

      ion-toolbar {
        --background: transparent;
      }
    }
  }
}

.modal-transparent-header {
  ion-header {
    &:after {
      height: 0;
    }

    ion-toolbar {
      --background: transparent;
    }
  }
}

.full-height-modal {
  --height: 90vh;
  --width: calc(100% - 10vw);
  --max-width: calc(768px - 10vw);
}

ion-input {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    appearance: none;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.close-btn::part(native) {
  padding: 0;
  width: 20px;
  height: 20px;
}

.ion-no-shadow {
  box-shadow: none
}

.ion-no-border {
  border: none;
  border-radius: 0;
}