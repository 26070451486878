ion-fab-button.fab-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  --ion-color-primary: #18FFFF;
  --background-color: #18FFFF;
  --background: #18FFFF;
  --color: black;
  box-shadow: 2px 6px 8px 0 rgb(0 0 0 / 40%), inset 0 10px 20px 0 black;
}

ion-button {
  --background: #F72585;
  color: black;
}

ion-button.modal-delete-btn {
  --border-color: #F72585;
  color: #F72585;
  --background: none;
}

ion-button.tertiary-buttons {
  --background: none;
  color: #F72585;
}

.tertiary-btn {
  --background: none !important;
  color: #F72585 !important;
}

.main-text-color {
  color: #F72585;
}

.ion-max-width {
  max-width: 768px;
}

.ion-padding-resp {
  --padding-top: 16px;
  --padding-end: 16px;
}

.block-centered {
  margin: 0 auto;
}

.item-cursor {
  cursor: pointer
}

.suspend-bg-rgba {
  background: (var(--ion-color-suspend-rgb));
}

ion-item.item-disabled {
  --background: var(--ion-color-suspend);
  opacity: .5;
}

.suspended-mask {
  box-sizing: unset;
  position: absolute;
  padding-left: 22px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#FF6D00, 0.5);

}

ion-tab-button {
  background: none;
  opacity: .5;
}

.tab-selected {
  border-bottom: 2px solid black;
  opacity: 1;
}

.toolbar-background {
  background: white;
}

.search-bar {
  --border-radius: 18px;
  --box-shadow: none;
  padding: 16px
}


.color-dark {
  color: var(--ion-color-dark)
}

.bg-white {
  background: white;
}

.bg-opacity {
  background: rgba(#fff, 0.25);
}

.bg-item-opacity {
  --background: rgba(255, 255, 255, .85);
}

.bg-none {
  --background: none;
}

.transparent {
  background: none;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap
}

.list-gap {
  gap: 3px 0;
}

.gallery-gap {
  gap: 10px;
}

.pos-absolute {
  position: absolute;
}

.item-inner {
  border-style: none;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-64 {
  font-size: 64px !important;
}

.font-bold {
  font-weight: 700;
}

.font-description {
  font-size: 24px !important;
}

.font-title {
  font-size: 30px !important;
}

.font-form-greeting {
  font-size: 16px !important;
}

.font-opacity {
  opacity: .5;
}

.pending-chip {
  --background: var(--chip-pending);
  --color: var(--ion-color-dark)
}

.approved-chip {
  --background: var(--ion-color-success);
  --color: var(--ion-color-dark)
}

.rejected-chip {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-dark)
}

@media screen and (max-width: 320px) {
  .font-title {
    font-size: 24px !important;
  }

  .font-description {
    font-size: 18px !important;
  }

  .font-form-greeting {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 320px) {
  .font-title {
    font-size: 18px !important;
  }

  .font-description {
    font-size: 14px !important;
  }

  .font-form-greeting {
    font-size: 12px !important;
  }

  .bg-opacity {
    background: none;
  }
}

@media (max-width:768px) {
  .ion-padding-resp {
    --padding-top: 0;
    --padding-end: 0;
  }

  .bg-opacity {
    background: none;
  }
}

@mixin wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.opacity-85 {
  opacity: 0.85;
}

.w-100 {
  width: 100%;
}

.alert-text {
  color: var(--ion-color-dark-shade);
  margin-bottom: 16px;
}

ion-app._gmaps_cdv_ .ion-page:not(._gmaps_cdv_),
ion-app._gmaps_cdv_ ion-modal:not(._gmaps_cdv_) {
  display: none;
}

.custom-searchbar,
.sc-ion-searchbar-ios-h {
  --border-radius: 18px;
  --box-shadow: none;
  padding: 8px;
}

ion-datetime-button::part(native) {
  width: 100%;
  height: 100%;

  &:hover {
    background: rgba(var(--ion-item-color, var(--ion-text-color, #000), 0.25));

  }
}

ion-datetime {
  color: black;
}

.footer-toolbar {
  padding: 0 16px;
  box-shadow: 0 10px 20px 5px rgb(0 0 0 / 40%);

  ion-button {
    --box-shadow: none
  }
}

.custom-loading {
  --background: none;
  --spinner-color: var(--ion-color-primary);

  .loading-wrapper.sc-ion-loading-md {
    box-shadow: none;
    -webkit-box-shadow: none
  }

  ion-spinner {
    transform: scale(4);
  }

}

.h-100 {
  height: 100%;
}

.img-shell {
  width: 28px;
  height: 28px;
}

.img-pearl {
  width: 28px;
  height: 28px;
}

.ion-text-ellipsis {
  text-overflow: ellipsis
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;


  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }
}

.ios {
  ion-toolbar {
    ion-button {
      &::part(native) {
        color: black;
      }
    }
  }

  ion-back-button {
    &::part(native) {
      color: black;
    }
  }
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
  text-wrap: wrap;
  white-space: pre-wrap;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.flagged-message {
  background: rgba(245, 0, 0, 0.3);
  color: var(--ion-color-dark);
  margin: -16px;
  margin-bottom: 16px;
  padding: 16px;

  p {
    font-weight: 200;
  }
}

.pointer {
  cursor: pointer;
}

.profile-item {
  --background: none;
  --inner-padding-end: 0;
}

.ion-align-self-start {
  align-self: flex-start;
}

.full-size-ad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: aquamarine;
}

.ads-bottom-wrap {
  height: 90px;
  width: 100%;
  max-width: 768px;
}

.ads-bottom {
  width: 100%;
  min-width: 320px;
  max-width: 768px;
  height: 100%;
  min-height: 50px;
  max-height: 90px;
}

.ion-text-prewrap {
  white-space: pre-wrap;
}

.more-btn {
  --padding-start: 0;
  --padding-end: 0;

  margin: 0;
  height: auto;
  max-width: 130px
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
}

.mock-link-icon-wrap {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
}

.mock-link-icon {
  position: absolute;
  left: 0;
  letter-spacing: -2px;
  line-height: 24px;
}

.d-none {
  display: none
}

.hidden {
  height: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* затемнение фона */
  z-index: 9999;
  /* установка на верхний уровень */
}

.suspended-bg {
  --background: var(--color-suspended);

}

.trash-bin-btn {
  height: auto;
  width: 56px;
}

.margin-icon {
  margin-right: 3px;
}

.invite-icon {
  padding: 5px
}